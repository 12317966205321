<style scoped>
    .modal-menu {
        flex-shrink: 1;
        width: 300px;
        height: 100%;
        box-sizing: border-box;
        border-right: 1px solid rgb(220, 225, 230);
    }

    .modal-menu .exit-btn {
        width: 26px;
        height: 26px;
        border-radius: 4px;
        position: absolute;
        top: 14px;
        left: 10px;
        padding: 0;
        border: 1px solid rgb(180, 190, 200);
    }

    .modal-menu .exit-btn:hover {
        background-color: rgb(80, 90, 100) !important;
        border-color: rgb(80, 90, 100) !important;
    }

    .modal-menu .exit-btn .exit-btn-icon {
        width: 50%;
        height: 50%;
        color: rgb(80, 90, 100);
        transition: 0.15s ease;
    }

    .modal-menu .exit-btn:hover .exit-btn-icon {
        color: white;
    }

    .exit-btn-icon {
        margin-left: auto;
        margin-right: auto;
    }

    .modal-menu hr {
        border-top: none;
        border-right: none;
        border-bottom: 1px solid rgb(220, 225, 230);
        border-left: none;
        margin: 0;
    }

    .modal-menu-items {
        overflow-y: auto;
        overflow-y: overlay;
        height: calc(100vh - 310px);
        margin-right: -30px;
        position: relative;
        padding-bottom: 30px;
        box-sizing: border-box;
    }

    .modal-menu-items::-webkit-scrollbar {
        display: none;
    }

    .modal-menu-items {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .modal-menu-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgb(220, 225, 230);
        padding: 18px 15px;
        cursor: pointer;
        transition: 0.15s ease;
        position: relative;
        margin-right: 30px;
    }

    .modal-menu-item:hover {
        padding-left: 20px;
        padding-right: 10px;
        background-color: rgb(246, 248, 250);
    }

    .modal-menu-item > span {
        color: rgb(30, 40, 50);
        font-weight: 500;
    }

    .no-icon-margin-left {
        margin-left: 28px;
    }

    .checked-icon {
        width: 16px;
        height: 16px;
        color: #4EA600;
        margin-right: 12px;
    }

    .unchecked-icon {
        width: 16px;
        height: 16px;
        color: #FF416B;
        margin-right: 12px;
    }

    .modal-menu-item-open > .checked-icon, .modal-menu-item-open > .unchecked-icon {
        color: white;
    }

    .checked-icon.visible, .unchecked-icon.visible  {
        visibility: visible;
    }

    .chevron-icon {
        width: 16px;
        height: 16px;
        color: rgb(50, 60, 70);
        margin-left: auto;
    }

    .modal-menu-item-open > .chevron-icon {
        color: white;
    }

    .modal-menu-item.selected {
        z-index: 2;
    }

    .modal-menu-item-open {
        display: flex;
        align-items: center;
        position: absolute;
        top: -1px;
        left: 0;
        width: calc(100% - 28px);
        height: calc(100% + 2px);
        padding: 18px 15px;
        cursor: pointer;
        transition: 0.15s ease;
        background: linear-gradient(90deg, #1e73be, #00a1cf);
        box-sizing: border-box;
        color: white;
        opacity: 0;
    }

    .modal-menu-item:hover .modal-menu-item-open {
        padding-left: 20px;
        padding-right: 10px;
    }

    .modal-menu-item.selected .modal-menu-item-open {
        width: calc(100% + 4px);
        opacity: 1;
    }

    .modal-menu-item-open:after {
        position: absolute;
        right: -20px;
        top: 0;
        content: '';
        width: 0;
        height: 0;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        border-left: 20px solid #00a1cf;
    }

    .modal-tabs {
        width: calc(100% - 300px);
    }

    ::v-slotted(.modal-tabs > div::-webkit-scrollbar) {
        width: 6px;
    }

    ::v-slotted(.modal-tabs > div) {
        overflow-y: auto;
        overflow-y: overlay;
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        box-sizing: border-box;
        scrollbar-width: thin;
    }

    ::v-slotted(.hide-modal-tab) {
        display: none !important;
    }

    ::v-slotted(.modal-tabs form > div) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    ::v-slotted(.modal-tabs form.form-column) {
        padding: 30px 20px 0 20px !important;
    }

    ::v-slotted(.modal-tabs form.form-column > div) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0px 15px 20px 15px;
    }

    ::v-slotted(.modal-tabs form) {
        padding: 30px 15px 0 20px;
    }

    ::v-slotted(.modal-tabs form.form-column) {
        display: flex;
    }

    .modal-menu-footer {
        height: 215px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
    }

    .modal-menu-footer ::v-slotted(.button) {
        width: 100%;
        text-align: center;
        justify-content: center;
        height: 50px;
    }
</style>

<template>
    <div class="modal-menu">
        <BaseButton class="white-button exit-btn" @click="onExit">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'times']" class="exit-btn-icon" />
            </template>
        </BaseButton>
        <div class="modal-menu-head">
            <slot name="modalMenuHead" />
        </div>
        <hr>
        <div class="modal-menu-items" ref="modalMenuItemsContainer">
            <div v-for="menuItem in menuItems" :key="menuItem.name">
                <div class="modal-menu-item" v-if="menuItem.display == null || menuItem.display === true" @click="onMenuItemClick($event, {name: menuItem.name, alterName: menuItem.alterName, tab: menuItem.tab})" :data-tab-name="menuItem.name">
                    <font-awesome-icon :icon="['fas', 'check-circle']" v-if="menuItem.checked" :class="'checked-icon '" />
                    <font-awesome-icon :icon="['fas', 'times-circle']" v-if="menuItem.unchecked" :class="'unchecked-icon '" />
                    <span :class="{'no-icon-margin-left': !menuItem.checked && !menuItem.unchecked}">{{ menuItem.label }}</span>
                    <font-awesome-icon :icon="['fas', 'chevron-right']" class="chevron-icon" />
                    <div class="modal-menu-item-open">
                        <font-awesome-icon :icon="['fas', 'check-circle']" v-if="menuItem.checked" :class="'checked-icon '" />
                        <font-awesome-icon :icon="['fas', 'times-circle']" v-if="menuItem.unchecked" :class="'unchecked-icon '" />
                        <span :class="{'no-icon-margin-left': !menuItem.checked && !menuItem.unchecked}">{{ menuItem.label }}</span>
                        <font-awesome-icon :icon="['fas', 'chevron-right']" class="chevron-icon" />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-menu-footer">
            <slot name="modalMenuFooter" />
        </div>
    </div>

    <div class="modal-tabs" ref="modalTabsContainer">
        <slot name="modalTabs" />
    </div>
</template>

<script>
    import BaseButton from '../Base/BaseButton';
    import $ from 'jquery';

    export default {
        name: 'ModalMenu',
        components: {
            BaseButton
        },
        emits: ['onExit', 'update:startTab', 'preloadData'],
        props: {
            menuItems: {
                type: Array,
                default: []
            },
            startTab: {
                type: String,
                default: ''
            },
            repairType: {
                type: String,
                default: ''
            },
            changeModeParent: {
                type: Boolean,
                default: false
            },
            forceCurrentTab: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                currentTab: null
            }
        },
        watch: {
            startTab: {
                handler(startTab) {
                    this.currentTab = startTab;
                    this.handleTabs();
                },
            },
            forceCurrentTab: {
                handler(newTab) {
                    if (newTab == null) return
                    this.currentTab = newTab;
                    this.handleTabs();
                }
            }
        },
        mounted() {
            if(this.startTab) {
                this.currentTab = this.startTab;
            }
            else if(this.menuItems.length > 0) {
                this.currentTab = this.menuItems[0].name;
            }

            this.handleTabs();
        },
        methods: {
            onExit() {
                if (this.autoExit === 'true') {
                    this.$emit('onExit', {
                        fromLastStep: false
                    });

                    this.exit();
                } else {
                    this.$emit('onExit', {
                        fromLastStep: false
                    });
                }
            },
            handleTabs() {
                let currentTab = this.currentTab;

                $(this.$refs.modalTabsContainer).children('div').each(function(index) {
                    $(this).removeClass('hide-modal-tab');

                    if ($(this).attr('data-tab-name') !== currentTab) {
                        $(this).addClass('hide-modal-tab');
                    }
                });

                $(this.$refs.modalMenuItemsContainer).children('div').children('.modal-menu-item.selected').removeClass('selected');
                $(this.$refs.modalMenuItemsContainer).children('div').children(`.modal-menu-item[data-tab-name=${currentTab}]`).addClass('selected');
            },
            onMenuItemClick(event, options = null) {
                if (this.changeModeParent == false) {
                    this.currentTab = $(event.target).closest('.modal-menu-item').attr('data-tab-name');
                    this.$emit('update:startTab', this.currentTab);
                }
                if (this.changeModeParent && options == null) {
                    this.currentTab = $(event.target).closest('.modal-menu-item').attr('data-tab-name');
                    this.$emit('update:startTab', this.currentTab);
                }
                else {
                    this.$emit('preloadData', {
                        event: event,
                        options: options
                    })
                }
            },
        }
    }
</script>