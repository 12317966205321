export default Object.freeze({
    ACHAT: "ACHAT",
    ADMINITRATION_DES_VENTES: "ADMINITRATION DES VENTES",
    ADV: "ADV",
    COMMERCIAL: "COMMERCIAL",
    COMMERCIAL_ITINERANT: "COMMERCIAL ITINERANT",
    COMMERCIAL_SEDENTAIRE: "COMMERCIAL SEDENTAIRE",
    COMPTABILITE: "COMPTABILITE",
    DIRECTION: "DIRECTION",
    FINANCIER: "FINANCIER",
    HOTLINE: "HOTLINE",
    LOGISTIQUE: "LOGISTIQUE",
    RESPONSABLE_MAINTENANCE: "RESPONSABLE MAINTENANCE",
    RESPONSABLE_PRODUCTION: "RESPONSABLE PRODUCTION",
    RESPONSABLE_QUALITE: "RESPONSABLE QUALITE",
    TECHNICIEN: "TECHNICIEN",
});
