<style scoped>
    .list-with-delete-container {
        flex: 1;
        width: 100%;
        margin: 0px 10px 0px 10px;
    }

    .flex-row-head {
        width: 100%;
        margin: 0;
    }

    .list-with-delete-container label {
        font-size: 14px;
        transition: 0.1s ease;
        color: rgb(100, 110, 120);
        text-transform: initial;
    }

    .list-with-delete {
        border: 1px solid #F1F1F1;
        border-radius: 4px;
        margin-top: 8px;
        padding: 8px 0;
        flex: 1;
        max-height: 500px;
        box-sizing: border-box;
        height: 100%;
    }

    .list-with-delete > div.line-separator:not(:last-child) {
        border: 1px solid #F1F1F1;
    }

    .list-element {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 15px;
        font-size: 13px;
        color: rgb(30, 40, 50);
        font-weight: 500;
    }

    .contact-detail-container div {
       flex:3
    }

    .contact-detail-container div:first-child {
       flex:1
    }

    .list-delete-element {
        background-color: rgb(100, 110, 120);
        border-radius: 50%;
        position: relative;
        width: 18px;
        height: 18px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        cursor: pointer;
        transition: 0.15s ease;
        color: #FFFFFF;
        font-size: 12px;
        padding: 1px;
        margin-left: 10px;
    }

    .list-head ul {
        display: flex;
        padding-left:0;
        flex-direction: row;
        list-style-type: none;
        margin-bottom: 2px;
    }

    .head {
        display: flex;
        flex-direction: column;
    }

    .flex-row {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .flex-row-line {
        width: calc(33% - 1px);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-left: 1px solid #F1F1F1;
    }

    .flex-row-element {
        width: 100%;
    }
</style>

<style>
    .list-element .contact-detail-container .inputeo, .list-element .contact-detail-container .radio-button-container {
        margin: 0px 10px;
        padding: 0px;
    }
</style>

<template>
    <div class="list-with-delete-container">
        <label v-if="label">{{ label }}</label>
        <div class='head' :class="{'flex-row-head': flexRow}">
            <div v-if="headElements.length > 0" class="list-head">
                <ul>
                    <li v-for="(headElement,index) in headElements" :key="index">{{ headElement }}</li>
                </ul>
            </div>
            <div class="list-with-delete" v-if="modelValue.length > 0" :class="{'flex-row': flexRow}">
                <div v-for="(item, index) in modelValue" v-bind:key="item.id" :class="{'line-separator': lineSeparator, 'flex-row-line': flexRow}">
                    <div class="list-element" :class="{'flex-row-element': flexRow}">
                        <slot name="content" v-bind="{ item, index }"></slot>
                        <div class="list-delete-element" :data-index="index" @click="deleteElement" :disabled="disabled" :class="disabled ? 'disabled' : ''" v-if="!item.hide">
                            <font-awesome-icon :icon="['fas', 'times']" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ListWithDelete',
        props: {
            label: {
                type: String,
                default: ''
            },
            modelValue: {
                type: Array,
                default: []
            },
            minimalElements: {
                type: String,
                default: "0"
            },
            headElements: {
                type: Array,
                default: []
            },
            disabled: {
                type: Boolean,
                default: false
            },
            lineSeparator: {
                type: Boolean,
                default: false
            },
            flexRow: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            deleteElement(event) {
                let elements = this.modelValue;

                if (elements.length > parseInt(this.minimalElements)) {
                    elements.splice(event.currentTarget.dataset.index, 1);
                    this.$emit('update:modelValue', elements);
                    this.$emit('onDelete', event.currentTarget.dataset.index);
                }
            }
        }
    }
</script>