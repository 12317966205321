<style scoped>
    .fieldset-container {
        flex: 1;
        width: 100%;
        margin: 15px 0px 15px 0px;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
    }

    .fieldset-container label {
        position: absolute;
        margin: -2px 0px 0px 34px;
        top: 0px;
        z-index: 99;
        background-color: #0d3b70;
        color: white;
        height: 28px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 13px;
    }

    .fieldset-container label::before {
        position: absolute;
        left: -20px;
        top: 0;
        content: '';
        width: 0;
        height: 0;
        border-bottom: 0 solid transparent;
        border-top: 28px solid transparent;
        border-right: 20px solid #0d3b70;
    }

    .fieldset-container label::after {
        position: absolute;
        right: -20px;
        top: 0;
        content: '';
        width: 0;
        height: 0;
        border-top: 0 solid transparent;
        border-bottom: 28px solid transparent;
        border-left: 20px solid #0d3b70;
    }

    .fieldset {
        border: 1px solid #F1F1F1;
        border-radius: 4px;
        margin-top: 8px;
        padding: 35px 8px 5px 8px;
        flex: 1;
        max-height: 380px;
        overflow-y: auto;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        overflow: visible;
    }

    .fieldset-column {
        flex-direction: column;
    }

    ::v-slotted(.fieldset-column > div) {
        display: flex;
    }

    :deep(.inputeo) {
        margin: 5px 10px 10px 10px;
    }
</style>

<template>
    <div class="fieldset-container">
        <label v-if="label">{{ label }}</label>
        <div class="fieldset" :class="{'fieldset-column': column}">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FieldSet',
        props: {
            label: {
                type: String,
                default: ''
            },
            column: {
                type: Boolean,
                default: false
            }
        }
    }
</script>